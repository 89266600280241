import React from "react"
import { Link, graphql } from "gatsby"
import "./custom.scss"
import ReactMarkdown from "react-markdown"
import SignIn from "../components/sign-in"
import Col from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { connect } from "react-redux"
const mapStateToProps = ({ continent, isLoggedin }) => {
  return { continent, isLoggedin }
}

const mapDispatchToProps = dispatch => {
  return {
    select_continent: continent =>
      dispatch({ type: `SELECT_CONTINENT`, id: continent }),
    reset_state: () => dispatch({ type: `RESET_STATE` }),
  }
}

const IndexPage = ({ select_continent, reset_state, isLoggedin, data }) => {
  reset_state()

  return (
    <Layout>
      <SEO title="Home" />

      <h1>{data.strapiHomePage.project_title}</h1>
      <ReactMarkdown source={data.strapiHomePage.project_description} />

      {isLoggedin ? (
        <div className="region-button-container">
          <Link
            to="/eastafrica"
            onClick={() => select_continent("East Africa")}
          >
            <div className="region-button" id="eastafrica-button">
              <div className="region-button-text">East Africa</div>
            </div>
          </Link>{" "}
          <br />
          {/* <Link to="/southasia" onClick={() => select_continent("South Asia")}> */}
          <div className="south-asia-button">
            <div className="region-button" id="southasia-button">
              <div className="region-button-text">South Asia</div>
            </div>
            <div className="coming-soon-text" id="coming-soon-text">Coming Soon</div>
          </div>
          {/* </Link> */}
        </div>
      ) : (
          <SignIn />
        )}
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiHomePage {
      project_title
      project_description
    }
  }
`
export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
