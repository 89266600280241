import React, { Component } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Alert from "react-bootstrap/Alert"
import { connect } from "react-redux"
import Spinner from "react-bootstrap/Spinner"
import "./sign-in.scss"

const mapStateToProps = ({ isLoggedin }) => {
  return { isLoggedin }
}

const mapDispatchToProps = dispatch => {
  return {
    sign_in: jwt_token =>
      dispatch({
        type: `SIGN_IN`,
        jwt_token,
      }),
  }
}

class SignIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      identifier: "",
      password: "",
      errorIsVisible: false,
      isLoading: false,
    }
  }

  componentDidMount() {
    this.setState({
      isLoading: false
    })
  }
  handleSignIn = e => {
    const form = e.currentTarget
    this.setState({
      errorIsVisible: false,
      isLoading: true
    })
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }
    e.preventDefault()
    fetch("https://bigdata-in-ag.uc.r.appspot.com/auth/local", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: this.state.identifier,
        password: this.state.password,
      }),
    })
      .then(response => response.json())
      .then(result => {
        if (result.jwt) {
          this.props.sign_in(result.jwt)
        } else {
          this.setState({
            errorIsVisible: true,
            isLoading: false
          })
        }
      })
  }

  handleEmailChange = e => {
    this.setState({
      identifier: e.target.value,
    })
  }

  handlePasswordChange = e => {
    this.setState({
      password: e.target.value,
    })
  }
  render() {
    const { errorIsVisible, isLoading } = this.state
    return (
      <div className="sign-in-container">
        <h3 className="sign-in-header">Sign In</h3>
        <Form onSubmit={this.handleSignIn}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={this.handleEmailChange}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={this.handlePasswordChange}
            />
          </Form.Group>
          <Button id="sign-in-button" type="submit" disabled={isLoading}>
            {isLoading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                id="sign-in-spinner"
                show={false}
              />
            ) : null}
            Submit
          </Button>
        </Form>
        <Alert variant="danger" show={errorIsVisible}>
          Invalid email and/or password
        </Alert>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
